import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'src/app/shared/services/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

    constructor(
        private router: Router,
        public jwtHelper: JwtHelperService,
        private cookieService: CookieService,
    ) {
    }

    public isAuthenticated(): boolean {
        const token = this.cookieService.getCookie('token');
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
      }
    
      canActivate() {
        if (this.isAuthenticated()) {
          return true;
        } else {
          localStorage.clear();
          this.cookieService.clearCookies();
          this.router.navigateByUrl('/sessions/signin');
        }
      }
      canDeactivate(){
        if (this.isAuthenticated()) {
          return false;
        }else {
          return true;
        }

      }
}
