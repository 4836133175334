import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

type fileTypes = 'pdf' | 'csv' | 'excel';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  @Output("sendData")
  public sendData = new EventEmitter();
  // public downloadType: fileTypes | null;
  downloadType: FormControl = new FormControl('pdf');
  alwaysDownloadFormat: FormControl = new FormControl(true);

  constructor() { }

  ngOnInit() {
    // this.downloadType = null;
  }

  sendEventData(status) {
    this.sendData.emit({ status: status, downloadType: status === 'download' ? this.downloadType.value : null });
  }

}
