import {  Component, EventEmitter, OnInit, Output,  } from '@angular/core';

@Component({
  selector: 'app-sucessful-modal',
  templateUrl: './sucessful-modal.component.html',
  styleUrls: ['./sucessful-modal.component.scss']
})
export class SucessfulModalComponent implements OnInit {
  @Output("sendData")
  public sendData = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }

}