import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Output("sendData")
  public sendData = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }

}
