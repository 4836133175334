import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { MemoryService } from 'src/app/shared/services/memory.service';
import { ProxyService } from 'src/app/shared/services/proxy.service';
import {filter, map, mergeMap} from 'rxjs/operators';
@Component({
  selector: 'app-common-sidebar',
  templateUrl: './common-sidebar.component.html',
  styleUrls: ['./common-sidebar.component.scss']
})
export class CommonSidebarComponent implements OnInit, OnDestroy {

  public active = 'tasks';
  insideActiveTab:string;
  userName: string;
  currentUrl: string;
  subscriptions: Subscription[] = [];
  private urlToKeyMap = new Map([
    ['tasks', { active: 'tasks' }],
    ['modules', { active: 'modules' }],
    ['characteristics', { active: 'characteristics' }],
    ['questionnaire', { active: 'questionnaire' }],
    ['templates', { active: 'templates' }],
    ['holidays', { active: 'holidays' }],
    ['user-content', { active: 'usercontent' }],
    ['user', { active: 'user' }],
    ['settings', { active: 'settings' }],
    ['news', { active: 'news' }],
    ['audit', { active: 'audit' }],
    ['user-settings', { active: 'user-settings' }],
  ]);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public permission: MemoryService,
    public proxyService: ProxyService,
    public cookieService: CookieService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.active = this.router.url.split("/")[1];
        if (this.router.url.split("/").length > 1) {
          this.insideActiveTab = this.router.url.split("/")[2];
        }
        this.activatedRoute.params.subscribe((params) => {
          if (params.entityUuid) {
            this.insideActiveTab = params.entityUuid;
          }
        });
        const activePage = this.activatedRoute.snapshot.queryParams.activePage;
        if (activePage) {
          this.active = this.router.url.split("/")[1].split("?")[0];
        }
      });
  }


  ngOnInit() {
    this.currentUrl = this.router.url.split('/')[1];
    this.active = this.urlToKeyMap.get(this.currentUrl).active;
    // if (localStorage.getItem('userName')) {
    //   this.userName = localStorage.getItem('userName')
    // }
    // this.getAllPermissions();
    this.getAccountDetails();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  activate(tab: string) {
    this.active = tab;
    // localStorage.setItem('activeSideBar', this.active);
    this.clearActiveTabStorage();
  }

  clearActiveTabStorage() {
    var keys = Object.keys(localStorage);
    var i = keys.length;
    while (i--) {
      const keyName = localStorage.key(i).split('-');
      if (keyName[1] === 'activeTab') {
        localStorage.removeItem(localStorage.key(i));
      }
    }
  }

  logout(): void {
      this.subscriptions.push(
        this.authService.logout()
          .subscribe((res: any) => {
            localStorage.clear();
            this.cookieService.clearCookies();
            this.router.navigateByUrl('/sessions/signin');
          }, (error: any) => {
          }),
      );
  }

  getAccountDetails(): void {
    this.subscriptions.push(
      this.authService.getAccountDetails()
        .subscribe((res: any) => {
          if(res) {
            this.userName = res.payload.name;
          }
          
        }, (error: any) => {
        }),
    );
}

}
