import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemoryService {

  public permission: any;
  constructor() { }
  
  setPermission(data) {
    this.permission = data;
  }

  getPermission(key) {
    return this.permission[key];
  }

  getAllPermission() {
    return this.permission;
  }

  hasReadPermission(attribute: string) {
    return this.permission && this.permission[attribute] && ["Read-write", "Read-only"].indexOf(this.permission[attribute]) !== -1
  }

  hasWritePermission(attribute: string) {
    return this.permission && this.permission[attribute] && this.permission[attribute] === "Read-write";
  }
}
