import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-question-tip',
  templateUrl: './question-tip.component.html',
  styleUrls: ['./question-tip.component.scss']
})
export class QuestionTipComponent implements OnInit {

  @Input("title")
  public title: string;

  @Output("sendData")
  public sendData = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }
}
