import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { LayoutSidebarComponent } from './shared/components/layouts/layout-sidebar/layout-sidebar.component';
import { RedirectComponent } from "./shared/components/redirect/redirect.component";
import { PermissionDataGuard } from './shared/resolvers/permission.data.guard';
import { AuthGaurd } from './shared/services/guards/auth.guard';
import { PermissionGuard } from "./shared/services/guards/permission.guard";

const routes: Routes = [
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: 'characteristics',
    loadChildren: () => import('./views/characteristics/characteristics.module').then(m => m.CharacteristicsModule),
    data: { scope: 'characteristic' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'modules',
    loadChildren: () => import('./views/modules/modules.module').then(m => m.ModulesModule),
    data: { scope: 'module' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'tasks',
    loadChildren: () => import('./views/tasks/tasks.module').then(m => m.TasksModule),
    data: { scope: 'task' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'action-point',
    loadChildren: () => import('./views/action-point/action-point.module').then(m => m.ActionPointModule),
    data: { scope: 'action point' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./views/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
    data: { scope: 'questionnaire' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'holidays',
    loadChildren: () => import('./views/holidays/holidays.module').then(m => m.HolidaysModule),
    data: { scope: 'holiday list' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
    data: { scope: 'user' },
    // canActivate: [PermissionGuard]
  },
  {
    path: 'user-content',
    loadChildren: () => import('./views/user-content/user-content.module').then(m => m.UserContentModule),
    data: { scope: 'user content' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'templates',
    loadChildren: () => import('./views/templates/templates.module').then(m => m.TemplatesModule),
    data: { scope: 'template' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'audit',
    loadChildren: () => import('./views/audit/audit.module').then(m => m.AuditModule),
    data: { scope: 'audit' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./views/news/news.module').then(m => m.NewsModule),
    data: { scope: 'news' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
    data: { scope: 'settings' },
    canActivate: [PermissionGuard]
  },
  {
    path: 'user-settings',
    loadChildren: () => import('./views/user-setting/user-setting.module').then(m => m.UserSettingsModule),
  },
];
const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'redirect',
    pathMatch: 'full'
  },
  {
    path: 'sessions',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
      }
    ],
  },
  {
    path: '',
    component: LayoutSidebarComponent,
    children: routes,
    canActivate: [AuthGaurd, PermissionDataGuard],
    canDeactivate: [AuthGaurd],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
