import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResizeColumnDirective } from './resize-column.directive';

const directives = [
    ResizeColumnDirective,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: directives,
    exports: directives,
})
export class SharedDirectiveModule { }
