import { IfStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CharacteristicsService } from '../../services/characteristics.service';

@Component({
  selector: 'app-characteristics-modal',
  templateUrl: './characteristics-modal.component.html',
  styleUrls: ['./characteristics-modal.component.scss']
})
export class CharacteristicsModalComponent implements OnInit {

  @Input("title")
  public title: string;

  @Input("uuid")
  public uuid: string;

  @Input("parentUuid")
  public parentUuid: string;

  @Input("targetcharacteristicsData")
  public targetcharacteristicsData: any;

  @Output("sendData")
  public sendData = new EventEmitter();

  customSearchControl: FormControl = new FormControl();
  addCharacterstics: FormGroup;
  subscriptions: Subscription[] = [];
  disableConditions = true;
  characteristicsData: any;
  characteristics = [];
  showCustomDropdown = false;
  customSearchKeyword: string;
  conditions = [];
  conditions1 = [
    { name: 'Equals', value: 'Equals' },
    { name: 'Not equals', value: 'Not equals' },
  ];
  conditions2 = [
    { name: 'Equals', value: 'Equals' },
    { name: 'Is null', value: 'Is null' },
  ];
  conditions3 = [
    { name: 'Is yes', value: 'Is yes' },
    { name: 'Is no', value: 'Is no' },
  ];
  conditions4 = [
    { name: 'Greater than', value: 'Greater than' },
    { name: 'Less than', value: 'Less than' },
  ];
  conditions5 = [
    { name: 'Greater than', value: 'Greater than' },
    { name: 'Less than', value: 'Less than' },
    { name: 'Equals', value: 'Equals' },
  ];
  values = [ ];

  constructor(
    private fb: FormBuilder,
    private characteristicsService: CharacteristicsService,
  ) { }

  get characteristic() {
    return this.addCharacterstics.get('characteristic');
  }
  get condition() {
    return this.addCharacterstics.get('condition');
  }
  get value() {
    return this.addCharacterstics.get('value');
  }
  ngOnInit() {
    this.getCharacteristics();
    this.initializeCharacteristicsForm();
    if (this.uuid && this.uuid.length) {
      this.getParentCharateristicsByUuid(this.parentUuid, this.uuid);
    }

    if(this.targetcharacteristicsData && this.targetcharacteristicsData.createdAt){
      this.initializeCharacteristicsForm(this.targetcharacteristicsData);
    }

    this.subscriptions.push(this.customSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.customSearchKeyword = value && value.length ? value.toLowerCase() : value;
      this.getCharacteristics();
    }));
  }

  initializeCharacteristicsForm(data?) {
    this.addCharacterstics = this.fb.group({
      characteristic: ['', Validators.required],
      condition: [data && data.condition ? data.condition : '', Validators.required],
      value: ['', Validators.required],
    });

    this.subscriptions.push(
      this.characteristic.valueChanges.subscribe((val) => {
        if (val) {
          this.disableConditions = false;
          this.values = val.options;
          this.setCondition(val.type);
        }
      })
    );
  }


  setCondition(value) {
    switch (value) {
      case 'Text': {
        this.conditions = this.conditions2;
        break;
      }
      case 'Yes/No': {
        this.conditions = this.conditions3;
        break;
      }
      case 'Numeric': {
        this.conditions = this.conditions5;
        break;
      }
      case 'Date': {
        this.conditions = this.conditions4;
        break;
      }
      default: {
        this.conditions = this.conditions1;
        break;
      }
    }
  }

  getParentCharateristicsByUuid(parentUuid: string, uuid: string) {
    this.subscriptions.push(
      this.characteristicsService.getCharacteristicsByUuid(parentUuid).subscribe((res: any) => {
        if (res) {
          this.characteristicsData = res.payload;
          this.initializeCharacteristicsForm(this.characteristicsData);
        }
      }, (error: any) => {
      }),
    );
  }

  getCharacteristics(): void {
    const data = {limit: 200};
    if(this.customSearchKeyword) {
    data['q'] = this.customSearchKeyword
    }
    this.subscriptions.push(
      this.characteristicsService.getCharacteristics(data).subscribe((res: any) => {
        if (res) {
          this.characteristics = [];
          res.payload.list.forEach((element) => {
            let data = {};
            data['title'] = element.title;
            data['type'] = element.type;
            data['uuid'] = element.uuid;
            data['options'] = element.options;
            this.characteristics.push(data);
          });
          if(this.targetcharacteristicsData && this.targetcharacteristicsData.target){
            const index = this.characteristics.findIndex((res)=> res.uuid === this.targetcharacteristicsData.target.uuid);
            const value = [];
            if(this.targetcharacteristicsData.value.length) {
              this.targetcharacteristicsData.value.forEach(element => {
                value.push(element.option.uuid);
              });
            }
            const specificValue = this.targetcharacteristicsData.specificValue ? this.targetcharacteristicsData.specificValue : '';
            if(index !== -1){
              this.addCharacterstics.patchValue({
                characteristic: this.characteristics[index],
                value: value ? value : specificValue
             });
            }
          } else if(this.targetcharacteristicsData && this.targetcharacteristicsData.parent){
            const index = this.characteristics.findIndex((res)=> res.uuid === this.targetcharacteristicsData.parent.uuid);
            const value = [];
            if(this.targetcharacteristicsData.value.length) {
              this.targetcharacteristicsData.value.forEach(element => {
                value.push(element.option.uuid);
              });
            }
            const specificValue = this.targetcharacteristicsData.specificValue ? this.targetcharacteristicsData.specificValue : '';
            if(index !== -1){
              this.addCharacterstics.patchValue({
                characteristic: this.characteristics[index],
                value: value.length ? value : specificValue
             });
            }
          }
        }
      }, (error: any) => {
      }),
    );
  }

  sendEventData(events) {
    if(this.targetcharacteristicsData && this.targetcharacteristicsData.createdAt) {
        this.sendData.emit({ status: events, type: 'update', data: events === 'save' ? this.addCharacterstics.value : null });
    } else {
        this.sendData.emit({ status: events, type: this.uuid && this.uuid.length ? 'update' : 'add', data: events === 'save' ? this.addCharacterstics.value : null });
    }
  }

  openCustomDropdown() {
    this.showCustomDropdown = !this.showCustomDropdown;
    this.customSearchKeyword = null;
  }
}
