import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {

  API_URL: string = environment.API_ENDPOINT;

  SESSIONS = '/sessions';

  FORGETPASSWORD = '/forgetPassword';

  RESETPASSWORD = '/resetPassword';

  USERS = '/users';

  CHARACTERISTICS = '/characteristics';

  INCONSISTENCIES = '/inconsistencies';

  DEPENDENCIES = '/dependencies';

  MODULES = '/modules';

  PARENTS = '/parents';

  CHILDREN = '/children';

  HOLIDAYS = '/holidays';

  ROLES = '/roles';

  Tasks = '/tasks';

  ACTIONS = '/actions';

  TEMPLATES = '/templates';

  QUESTIONNAIRE = '/questionnaire';

  TARGET = '/target';

  LISTS = '/list';

  NEWS = '/news';

  LOGS = '/logs';

  OBJECTS = '/objects';

  MODERATIONS = '/moderation';

  COMMENTS = '/comments';

  HISTORY = '/history';

  CUSTOMERS = '/customers';

  CUSTOMER = '/customer';

  IMPORT = '/import';

  SETTINGS = '/settings';

  DOWNLOAD = '/download';

  constructor() { }
  getUrl(path: string, params: any[] = []) {
    return !params.length
      ? [this.API_URL, path].join('')
      : [[this.API_URL, path].join(''), params.join('/')].join('/');
  }

}
