import {Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';

@Component({
  selector: 'app-undelete-modal',
  templateUrl: './undelete-modal.component.html',
  styleUrls: ['./undelete-modal.component.scss']
})
export class UndeleteModalComponent implements OnInit {
  @Output("sendData")
  public sendData = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }

}
