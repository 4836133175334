import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';

@Component({
  selector: 'app-module-undelete-modal',
  templateUrl: './module-undelete-modal.component.html',
  styleUrls: ['./module-undelete-modal.component.scss']
})
export class ModuleUndeleteModalComponent implements OnInit {

  @Output("sendData")
  public sendData = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }

}
