import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemoryService } from '../../services/memory.service';

const routePermissionMap = {
  'task': 'tasks',
  'module': 'modules',
  'characteristic': 'characteristics',
  'questionnaire': 'questionnaire',
  'template': 'templates',
  'holiday list': 'holidays',
  'user': 'user',
  'settings': 'settings',
  'news': 'news',
  'user content': 'user-content',
  'audit': 'audit',
};

const profileRoute = 'user-settings';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    private memoryService: MemoryService,
    private router: Router,
  ) { }

  ngOnInit() {
    let redirected = false;

    for (const permission of Object.keys(routePermissionMap)) {
      if (this.memoryService.hasReadPermission(permission)) {
        redirected = true;
        this.router.navigateByUrl(`/${routePermissionMap[permission]}`);
        return true;
      }
    }

    if (!redirected) {
      this.router.navigateByUrl(`/${profileRoute}`);
    }
  }

}
