import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IForgetPassword, ILogin, IResetPassword } from '../interface/auth.interface';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = false;
  // public isAuthenticated: boolean

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) {
  }

  /**
   * Logins auth service
   * @param body of type Login interface
   * @returns  user details object
   */
  login(body: ILogin) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}`),
      body
    );
  }



  /**
   * Forgets password
   * @param body  of type ForgetPassword interface
   * @returns  object
   */
  forgetPassword(body: IForgetPassword) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.FORGETPASSWORD}`),
      body
    );
  }


  /**
 * Resets password
 * @param body Password as string
 * @param token  token as string
 * @returns password IResPutAccount
 */
  resetPassword(body: IResetPassword, token: string) {
    return this.http.patch(this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.RESETPASSWORD}/${token}`),
      body,
    );
  }


  /**
   * Gets account details
   * @returns account details 
   */
  getAccountDetails() {
    return this.http.get(this.constantService.getUrl(`${this.constantService.USERS}/me`));
  }

  /**
  * Gets account permission
  * @returns account permission
  */
  getAccountPermission() {
    return this.http.get(this.constantService.getUrl(`${this.constantService.USERS}/me/permissions`));
  }
/**
 *  change Password
* @returns change password
 */
changePassword(body){
  return this.http.patch(this.constantService.getUrl(`${this.constantService.USERS}/me/password`),
  body
  );
}
  logout() {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.USERS}/me/logout`),
      {}
    );
  }

}
