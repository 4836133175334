import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class ActionPointService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  addActionPoint(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ACTIONS}`), body
    );
  }

  getActionPointByUuid(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}`),
    );
  }

  importActions(body) {
    return this.http.post(
      this.constantService.getUrl(`/import/actions`), body
    );
  }

  updateActionPoint(body, uuid: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}`),
      body
    );
  }

  getAllActionPoint(limit, type, value, direction?) {
    
    if (type === 'sort') {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ACTIONS}?limit=${limit}&${type}=${value},${direction}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ACTIONS}?limit=${limit}&${type}=${value}`),
      );
    }
  }

  getActionPoint() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}`),
    );
    
  }

  getAllActionPointUnderTasks(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}?task=${uuid}`),
    );
  }

  getActionPointUnderTasks(uuid: string, body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'&');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}?task=${uuid}${params}`),
    );
  }

  getAllApplicableCharacteristics(uuid: string,body: any) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}${this.constantService.CHARACTERISTICS}${params}`),
    );
  }

  getApplicableCharacteristicsByUuid(uuid: string, appId: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}${this.constantService.CHARACTERISTICS}/${appId}`),
    );
  }

  addApplicableCharacteristics(body, uuid: string) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}${this.constantService.CHARACTERISTICS}`), body
    );
  }

  updateApplicableCharacteristics(body, uuid: string, appId: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}${this.constantService.CHARACTERISTICS}/${appId}`),
      body
    );
  }

  deleteApplicableCharacteristics(accId: string, uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.MODULES}/${accId}${this.constantService.CHARACTERISTICS}/${uuid}`)
    );
  }

  deleteActionPoint(uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}`)
    );
  }

  deactiveActionPoint(uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}/${uuid}/active`), {}
    );
  }
}
