import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {


  @Input("deleted")
  public deleted: string;

  @Input("deletedItem")
  public deletedItem: string;

  @Output("sendData")
  public sendData = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }

}
