import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportService } from '../../services/import.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UploadToS3Service } from 'src/app/shared/services/upload-to-s3.service';
import { TasksService } from '../../services/tasks.service';
import { ActionPointService } from '../../services/action-point.service';
import { CharacteristicsService } from '../../services/characteristics.service';
@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  @ViewChild('importStatusModal', { static: false }) importStatusModal;

  subscriptions: Subscription[] = [];
  public uploadData: any;
  addFile: FormGroup;
  selectedFile: any;
  importStatus = 'inprogress';
  progress = 0;
  error: string;
  uploadResponse = { status: '', message: '', filePath: '' };
  currentUrl: string;
  parent: string;
  private urlToKeyMap = new Map([
    ['tasks', { title: 'Tasks', url: '/tasks' }],
    ['action-point', { title: 'Action Points', url: '/action-point' }],
    ['modules', { title: 'Modules', url: '/modules' }],
    ['characteristics', { title: 'Characteristics', url: '/characteristics' }],
    ['questionnaire', { title: 'Questionnaire', url: '/questionnaire' }],
    ['templates', { title: 'Templates', url: '/templates' }],
    ['holidays', { title: 'Holidays', url: '/holidays' }],
    ['user-content', { title: 'User Content', url: '/user-content' }],
    ['user', { title: 'User', url: '/user' }],
    ['news', { title: 'News', url: '/news' }],
    ['audit', { title: 'Audit', url: '/audit' }],

  ]);
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private importService: ImportService,
    private tasksService: TasksService,
    private actionPointService: ActionPointService,
    private characteristicsService: CharacteristicsService,
    private location: Location,
    private router: Router,
    private uploadToS3Service: UploadToS3Service,
  ) { }

  get fileSelect() {
    return this.addFile.get('fileSelect');
  }

  ngOnInit() {
    this.currentUrl = this.router.url.split('/')[1];
    this.parent = this.urlToKeyMap.get(this.currentUrl).title;
    //   this.activatedRoute.parent.url.subscribe((urlPath) => {
    //     this.currentUrl = urlPath[urlPath.length - 1].path;
    //     this.parent = this.urlToKeyMap.get(this.currentUrl).title;
    // })
    this.addFile = this.fb.group({
      fileSelect: ['', Validators.required],
    });
  }

  onChangeUpload(event): void {
    this.selectedFile = event.target.files[0];
    this.addFile.get('fileSelect').setValue(this.selectedFile);
    var reader = new FileReader();

    reader.onload = (event: ProgressEvent) => {
      const url = (<FileReader>event.target).result;
    }

    reader.readAsDataURL(event.target.files[0]);
    const extension = this.selectedFile.type.split('/').pop();
    this.subscriptions.push(
      this.uploadToS3Service.getUrlForUpload(extension, this.selectedFile.type)
        .subscribe((res: any) => {
          this.uploadData = res.payload;

        }, (error: any) => {
          this.selectedFile = null;
        }),
    );
  }

  uploadToS3(uploadData, file) {
    this.subscriptions.push(
      this.importService.uploadFile(uploadData, file)
        .subscribe((res: any) => {
          if (res && res.progress) {
            this.progress = res.progress;
          }
          if (res === null) {
            if (this.progress === 100) {
              
              if (this.parent === "Tasks") {
                this.tasksService.importTasks({ filePath: uploadData.fields.key }).subscribe(() => {
                  this.removeFile();
                  this.importStatus = 'success';
                }, err => {
                  this.importStatus = 'failed';
                })
              }
              if (this.parent === "Action Points") {
                this.actionPointService.importActions({ filePath: uploadData.fields.key }).subscribe(() => {
                  this.removeFile();
                  this.importStatus = 'success';
                }, err => {
                  this.importStatus = 'failed';
                })
              }
              if (this.parent === "Characteristics") {
                this.characteristicsService.importActions({ filePath: uploadData.fields.key }).subscribe(() => {
                  this.removeFile();
                  this.importStatus = 'success';
                }, err => {
                  this.importStatus = 'failed';
                })
              }
            }
          }
        }, (error: any) => {
          this.importStatus = 'failed';
        }),
    );
  }

  removeFile() {
    this.selectedFile = null;
    this.uploadData = null;
    this.addFile.get('fileSelect').setValue('');
    this.reloadComponent();
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  openImportStatus(importModal): void {
    if (this.uploadData && this.selectedFile) {
      this.uploadToS3(this.uploadData, this.selectedFile);
      this.modalService.open(importModal,
        { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'lg' })
        .result.then((result) => {
          if (result.status === 'stop') {
          }
        });
    }
  }

  parentUrl() {
    this.router.navigateByUrl(`${this.urlToKeyMap.get(this.currentUrl).url}`);
  }

  upload() {
    if (this.importStatus === 'inprogress' && this.progress < 46) {
      setTimeout(() => {
        this.progress = this.progress + 1;
      }, 1000);
    }
    if (this.progress < 100) {
      return this.progress;
    }
  }

  onSubmit() {
    // const formData = new FormData();
    // formData.append('file', this.addFile.get('fileSelect').value);

    // this.importService.uploadFile(formData).subscribe(
    //   (res) => this.uploadResponse = res,
    //   (err) => this.error = err
    // );
  }

  back() {
    this.location.back();
  }
}
