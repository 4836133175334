import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './layouts/layouts.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CharacteristicsModalComponent } from './characteristics-modal/characteristics-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ApplicableCharacteristicsComponent } from './applicable-characteristics/applicable-characteristics.component';
import { LoaderComponent } from './loader/loader.component';
import { ExportComponent } from './export/export.component';
import { ImportComponent } from './import/import.component';
import { QuestionTipComponent } from './question-tip/question-tip.component';
import { UndeleteModalComponent } from './undelete-modal/undelete-modal.component';
import { SucessfulModalComponent } from './sucessful-modal/sucessful-modal.component';
import { ModuleUndeleteModalComponent } from './module-undelete-modal/module-undelete-modal.component';

const components = [
    ApplicableCharacteristicsComponent,
    CharacteristicsModalComponent,
    DeleteModalComponent,
    ConfirmationModalComponent,
    ExportComponent,
    ImportComponent,
    QuestionTipComponent,
    UndeleteModalComponent,
    ModuleUndeleteModalComponent,
    SucessfulModalComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LayoutsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MatSelectModule,
    ],
    declarations: [
        components,
        LoaderComponent
    ],
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
   
})
export class SharedComponentsModule { }
