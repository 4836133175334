import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSidebarComponent } from './layout-sidebar/common-sidebar/common-sidebar.component';
import { LayoutSidebarComponent } from './layout-sidebar/layout-sidebar.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const components = [
    CommonSidebarComponent,
    LayoutSidebarComponent,
    AuthLayoutComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule
    ],
    declarations: components,
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutsModule { }
