import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemoryService } from 'src/app/shared/services/memory.service';
import { ProxyService } from 'src/app/shared/services/proxy.service';

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: './layout-sidebar.component.html',
  styleUrls: ['./layout-sidebar.component.scss']
})
export class LayoutSidebarComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private memoryService : MemoryService,
    private proxyService: ProxyService,
  ) { }

  ngOnInit() {
  }

}
