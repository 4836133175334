import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SharedComponentsModule } from "./components/shared-component-module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectiveModule } from "./directive/shared-directive.module";

@NgModule({
    imports: [
        CommonModule,
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectiveModule,
        RouterModule,
        NgbModule
    ],
    declarations: [

    ],
})
export class SharedModule { }
