import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared-module';
import { LayoutsModule } from './shared/components/layouts/layouts.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkTableModule } from '@angular/cdk/table';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './shared/services/httpconfig.interceptor';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { CookieService } from 'ngx-cookie-service';
import { JwtModule } from '@auth0/angular-jwt';
import { DatePipe } from '@angular/common';
import { PermissionDataGuard } from './shared/resolvers/permission.data.guard';
import {NgbDateCustomParserFormatter} from 'src/app/custom-date-format';
import {ToastrModule} from 'ngx-toastr';
import { RedirectComponent } from './shared/components/redirect/redirect.component'
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

export function tokenGetter() {
  return this.CookieService.get('token');
}

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    LayoutsModule,
    CdkTableModule,
    NgxDatatableModule,
    FullCalendarModule,
    NgbModule,
    MatSelectModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }, 
    CookieService, 
    DatePipe, 
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ],
  bootstrap: [AppComponent],
  entryComponents: [  
    LoaderComponent
  ]
})
export class AppModule { }
