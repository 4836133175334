import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MemoryService } from '../services/memory.service';
import { ProxyService } from '../services/proxy.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionDataGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private memoryService : MemoryService,
        private proxyService: ProxyService,
        private router: Router,
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.getAccountPermission().toPromise()
            .then((result: any) => {
                const permissionObj = {};
                result.payload.permissions.forEach(element => {
                 permissionObj[element.attribute.name.toLowerCase()] = element.access;
                });
                
                this.memoryService.setPermission(permissionObj);
                this.proxyService.sendUserPermission(true);

                return true;
            })
            .catch((error) => {
                return false;
            });
    }
}
