import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  getAllTasks(limit, sortKey, sortDirection, currentPage,   type?, value?) {
    if (type) {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.Tasks}?limit=${limit}&page=${currentPage}&${type}=${value}&sort=${sortKey},${sortDirection}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.Tasks}?limit=${limit}&page=${currentPage}&sort=${sortKey},${sortDirection}`),
      );
      
    }
  }

  getTasksByUuid(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}`),
    );
  }
  
  addTasks(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.Tasks}`), body
    );
  }

  importTasks(body) {
    return this.http.post(
      this.constantService.getUrl(`/import/tasks`), body
    );
  }

  updateTasks(body, uuid: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}`),
      body
    );
  }

  deleteTasks(uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}`)
    );
  }

  deactiveTasks(uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}/active`), {}
    );
  }

  getTasksUnderModule(uuid: string, body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'&');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.Tasks}?module=${uuid}${params}`),
    );
  }

  getAllApplicableCharacteristics(uuid: string, body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}${this.constantService.CHARACTERISTICS}${params}`),
    );
  }

  getApplicableCharacteristicsByUuid(uuid: string, appId: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}${this.constantService.CHARACTERISTICS}/${appId}`),
    );
  }

  addApplicableCharacteristics(body, uuid: string) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}${this.constantService.CHARACTERISTICS}`), body
    );
  }

  updateApplicableCharacteristics(body, uuid: string, appId: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.Tasks}/${uuid}${this.constantService.CHARACTERISTICS}/${appId}`),
      body
    );
  }

  deleteApplicableCharacteristics(taskId: string, uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.Tasks}/${taskId}${this.constantService.CHARACTERISTICS}/${uuid}`)
    );
  }
}
