import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CharacteristicsService } from '../../services/characteristics.service';

@Component({
  selector: 'app-applicable-characteristics',
  templateUrl: './applicable-characteristics.component.html',
  styleUrls: ['./applicable-characteristics.component.scss']
})
export class ApplicableCharacteristicsComponent implements OnInit {

  @Input("title")
  public title: string;

  @Output("sendData")
  public sendData = new EventEmitter();

  @Input("characteristicsData")
  public characteristicsData: any;
  customSearchControl: FormControl = new FormControl();
  addCharacterstics: FormGroup;
  subscriptions: Subscription[] = [];
  disableConditions = true;
  customSearchKeyword: string;
  characteristics = [];
  showCustomDropdown = false;
  values = []

  constructor(
    private fb: FormBuilder,
    private characteristicsService: CharacteristicsService,
  ) { }

  get applicableChanrateristics() {
    return this.addCharacterstics.get('applicableChanrateristics') as FormArray;
  }

  ngOnInit() {
    this.initializeCharacteristicsForm();
    this.getAllCharacteristics();
    this.subscriptions.push(this.customSearchControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.customSearchKeyword = value && value.length ? value.toLowerCase() : value;
      this.getAllCharacteristics();
    }));
  }

  initializeCharacteristicsForm(data?) {
    this.addCharacterstics = this.fb.group({
      applicableChanrateristics: this.fb.array([]),
    });

    if (data && data.length) {
      let i = 0;
      data.forEach(element => {
        if (data[data.length - 1].uuid === element.uuid) {
          element.separator = null;
        } else {
          element.separator = data[i + 1].separator;
        }
        this.getCharacteristicsByUuid(element);
        i++;
      });
    } else {
      this.createFormArray();
    }

    // this.subscriptions.push(
    //   this.applicableChanrateristics.valueChanges.subscribe((val) => {
    //     if (val) {

    //     }
    //   })
    // );

    (this.applicableChanrateristics).controls.forEach(
      control => {
        control.get('characteristic').valueChanges
          .subscribe((value) => {
            if (value) {
              control.patchValue({
                condition: '',
                value: '',
                separator: ''
              });
              if (value.type !== 'Yes/No') {
                control.get('value').setValidators([Validators.required]);
                control.get('value').updateValueAndValidity();
              } else {
                control.get('value').clearValidators();
                control.get('value').updateValueAndValidity();
              }
            }
          });
      }
    );
  }

  createFormArray(options?): void {
    // const index = options ? this.characteristics.findIndex(control => control.uuid === options.characteristic.uuid) : -1;
    const value = [];
    if (options && options.value.length) {
      options.value.forEach(element => {
        value.push(element.option.uuid);
      });
    }
    (this.addCharacterstics.get('applicableChanrateristics') as FormArray).push(
      this.fb.group({
        characteristic: [options && options.characteristicsData ? options.characteristicsData : '', Validators.required],
        condition: [options && options.condition ? options.condition : '', Validators.required],
        value: [value.length ? value : options && options.specificValue ? options.specificValue : ''],
        separator: [options && options.separator ? options.separator : ''],
        showCustomDropdown: false,
      })
    );
    const finalLength = this.applicableChanrateristics.length - 1;
    for (let i = 0; i < finalLength; i++) {
      this.applicableChanrateristics['controls'][i]['controls']['separator'].setValidators([Validators.required]);
      this.applicableChanrateristics['controls'][i]['controls']['separator'].updateValueAndValidity();
    }


  }

  delete(option) {
    const formArray = this.addCharacterstics.get('applicableChanrateristics') as FormArray;
    const index = formArray.controls.findIndex(control => control === option);

    formArray.removeAt(index);
    if (this.applicableChanrateristics.value.length === 0) {
      this.createFormArray();
    }

    const finalLength = this.applicableChanrateristics.length - 1;
    if (index > 0) {
      this.applicableChanrateristics['controls'][index - 1]['controls']['separator'].clearValidators();
      this.applicableChanrateristics['controls'][index - 1]['controls']['separator'].updateValueAndValidity();
    }
    /* for(let i= 0; i < finalLength; i++) {
      this.applicableChanrateristics['controls'][i]['controls']['separator'].setValidators([Validators.required]);
      this.applicableChanrateristics['controls'][i]['controls']['separator'].updateValueAndValidity();
    } */
  }


  getAllCharacteristics(): void {
    const data = { active: "1" };
    if (this.customSearchKeyword) {
      data['q'] = this.customSearchKeyword
    }
    this.subscriptions.push(
      this.characteristicsService.getCharacteristics(data).subscribe((res: any) => {
        if (res) {
          this.characteristics = [];
          res.payload.list.forEach((element) => {
            let data = {};
            data['title'] = element.title;
            data['type'] = element.type;
            data['uuid'] = element.uuid;
            data['options'] = element.options;
            this.characteristics.push(data);
          });
          if (this.characteristicsData && this.characteristicsData.createdAt) {
            this.initializeCharacteristicsForm(this.characteristicsData.applicableCharacteristics);
          }
        }
      }, (error: any) => {
      }),
    );
  }

  getCharacteristicsByUuid(option) {
    this.subscriptions.push(
      this.characteristicsService.getCharacteristicsByUuid(option.characteristic.uuid).subscribe((res: any) => {
        if (res) {
          option['characteristicsData'] = res.payload;
          this.createFormArray(option);
        }
      }, (error: any) => {
      }),
    );
  }

  markArrayDirty(formArray: FormArray) {
    formArray.controls.forEach(control => {
      this.markGroupDirty(control as FormGroup);
    });
  }

  markGroupDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      this.markControlDirty(formGroup.get(key) as FormControl);
    });
  }

  markControlDirty(formControl: FormControl) {
    formControl.markAsDirty();
  }

  sendEventData(events) {
    this.applicableChanrateristics.value.forEach(element => {
      delete element.showCustomDropdown;
    });
    if (events === 'save') {
      this.markArrayDirty(this.applicableChanrateristics);
      if (this.applicableChanrateristics.valid) {
        if (this.characteristicsData && this.characteristicsData.createdAt) {
          this.sendData.emit({ status: 'update', data: this.applicableChanrateristics.value });
        } else {
          this.sendData.emit({ status: events, data: this.applicableChanrateristics.value });
        }
      }
    } else {
      this.sendData.emit({ status: events, data: null });
    }

  }

  openCustomDropdown(option) {
    const formArray = this.addCharacterstics.get('applicableChanrateristics') as FormArray;
    const index = formArray.controls.findIndex(control => control === option);
    const previousValue = formArray.controls[index]['controls']['showCustomDropdown'].value;
    formArray.at(index).patchValue({
      showCustomDropdown: !previousValue,
    });
    this.customSearchKeyword = null;
  }

}
