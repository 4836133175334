import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class CharacteristicsService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  createCharacteristics(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}`),
      body
    );
  }

  importActions(body) {
    return this.http.post(
      this.constantService.getUrl(`/import/characteristics`), body
    );
  }

  updateCharacteristics(body, uuid: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}`),
      body
    );
  }

  getCharacteristics(body?) {
    if(body) {
      const keys = Object.keys(body);
      const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${params}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}`),
      );
    }
  }

  getAllListCharacteristics(body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${params}`),
    );
  }

  searchListCharacteristics(limit, type, value, direction?) {
    if (type === 'sort') {
      return this.http.get(
        this.constantService.getUrl(this.constantService.CHARACTERISTICS + `?${type}=${value},${direction}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(this.constantService.CHARACTERISTICS + `?limit=${limit}${type}=${value}`),
      );
    }
  }

  getCharacteristicsByUuid(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}`),
    );
  }

  deleteCharacteristics(uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}`),
    );
  }

  deactiveCharacteristics(uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}`),{}
    );
  }

  listActivateStatus(uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}/activation/toggle`),
      {}
    );
  }


  getAllInconsistencies(body: any) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${this.constantService.INCONSISTENCIES}${params}`),
    );
  }

  addInconsistencies(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${this.constantService.INCONSISTENCIES}`),
      body
    );
  }

  updateInconsistencies(body, uuid: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${this.constantService.INCONSISTENCIES}/${uuid}`),
      body
    );
  }
  
  getAllDependencies(body: any) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${this.constantService.DEPENDENCIES}${params}`),
    );
  }

  getAllParentCharacteristics(uuid: string, body: any) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.PARENTS}${params}`),
    );
  }

  getParentCharacteristicsByUuid(uuid: string, depid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}${this.constantService.DEPENDENCIES}/${uuid}${this.constantService.PARENTS}/${depid}`),
    );
  }

  addParentCharacteristics(uuid: string, body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.PARENTS}`), body
    );
  }

  updateParentCharacteristics(uuid: string, depid: string, body) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.PARENTS}/${depid}`), body
    );
  }

  deleteParentCharacteristics(uuid: string, depid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.PARENTS}/${depid}`)
    );
  }

  getAllChildrenCharacteristics(uuid: string, body: any) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.CHILDREN}${params}`),
    );
  }

  getChildrenCharacteristics(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.CHILDREN}`),
    );
  }

  getChildrenCharacteristicsFull(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CHARACTERISTICS}/${uuid}${this.constantService.CHILDREN}?limit=500`),
    );
  }
}
